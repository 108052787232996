<template>
  <div class="forget" :class="{noScollForNoLogin_safari:os.safari}">
       <div class="forget-form">
           <h2>重置密码</h2>

           <div class="form form_common">
               <van-form @submit="onSubmit" ref="f_form">
                    <van-field v-model="mobile" autocomplete="off" name="mobile" type="number" maxlength="11" placeholder="手机号码" :rules="[{ required: true, message: '请输入手机号码' },{pattern,message: '手机号码格式错误'}]"/>
                    <div class="form_code">
                        <div class="form_code_input">
                            <van-field v-model="yzm" autocomplete="off" name="yzm" type="number" maxlength="4" placeholder="手机验证码" :rules="[{ required: true, message: '请输入手机验证码' }]"/>
                        </div>
                        <div class="btn" @click="onSendCode">{{btnTxt}}</div>
                    </div>
                    <van-field v-model="password" autocomplete="off" type="password" name="password" placeholder="输入新密码" :rules="[{ required: true, message: '请输入新密码' }]"/>
                </van-form>

                <c-button bClass="btn_reset" @btnClick="onForget">重置密码</c-button>
           </div>
       </div>
  </div>
</template>

<script>
import { Field, Form } from "vant";
import CButton from "../../components/form/CButton.vue";
import { dialogAlert2 } from "../../lib/util";
import { mapActions, mapState } from "vuex";
import md5 from "js-md5";
export default {
    components: {
        [CButton.name]: CButton,
        [Field.name]: Field,
        [Form.name]: Form,
    },
    data() {
        return {
            pattern: /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            mobile: "",
            password: "",
            yzm: "",

            canClick: true,
            time: null,
            btnTxt: "获取验证码",
            timeNum: 60,

            md5Code: "",
            resPhone: "",
        };
    },
    computed: {
        ...mapState({
            os: (state) => state.os,
        }),
    },
    methods: {
        ...mapActions({
            requestResetPassword: "user/requestResetPassword",
            requestSendCode: "user/requestSendCode",
        }),
        onSendCode() {
            if (this.canClick) {
                if (this.mobile) {
                    if (this.pattern.test(this.mobile)) {
                        this.requestSendCode({ mobile: this.mobile, exist: 1 }).then(
                            (res) => {
                                this.canClick = false;
                                this.btnTxt = "60s";
                                this.intervalTime();
                                this.md5Code = res.yzm;
                                this.resPhone = res.mobile;
                            }
                        );
                    } else {
                        dialogAlert2("手机号码格式错误");
                    }
                } else {
                    dialogAlert2("请输入手机号码");
                }
            }
        },
        onSubmit(e) {
            let _md5Code = md5(e.yzm);

            if (e.mobile != this.resPhone) {
                dialogAlert2("手机号码验证错误");
                return false;
            }

            if (_md5Code != this.md5Code) {
                dialogAlert2("验证码验证错误");
                return false;
            }

            this.requestResetPassword(e).then(() => {
                this.$router.replace({
                    name: "home",
                });
            });
        },
        onForget() {
            this.$refs.f_form.submit();
        },
        intervalTime() {
            this.time = setInterval(() => {
                if (this.timeNum <= 1) {
                    clearInterval(this.time);
                    this.btnTxt = "获取验证码";
                    this.timeNum = 60;
                    this.canClick = true;
                } else {
                    this.timeNum = this.timeNum - 1;
                    this.btnTxt = this.timeNum + "s";
                }
            }, 1000);
        },
    },
    created() {
        let _uuid = this.$cookies.isKey("user_id");
        if (_uuid) {
            this.$router.replace({
                name: "home",
            });
        }
    },
};
</script>

<style>
.forget{padding-top: 47px; height: 100vh; background: #fff;}
.forget-form{padding:20px 20px 0;}
.forget-form h2{font-size: 20px;color: #2E2E30; text-align: center; font-weight: normal;}
.forget-form .form{margin-top: 20px;}
.forget-form .btn_reset{margin-top: 32px;}
</style>